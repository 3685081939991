

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // initialization of go to




        // initialization of unfold
            var unfold = new HSUnfold('.js-hs-unfold-invoker').init();

            // initialization of unfold
         $('.js-hs-search-unfold-invoker').each(function() {
           var searchUnfold = new HSUnfold($(this), {
             afterOpen: function() {
               $('#searchSlideDownControl').focus();
             }
           }).init();
         });


          }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on('load', function () {




  });



  $(document).on('ready', function () {

    $('.cpc-slick').slick({
      "prevArrow": "<span class=\"fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle bg-success text-white ml-sm-n2\"></span>",
      "nextArrow": "<span class=\"fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle bg-success text-white mr-sm-2 mr-xl-0\"></span>",
      "slidesToShow": 3,
      "slidesToScroll": 3,
      "centerMode": false,


      "arrows": true,
      "infinite": false,

      "responsive": [{
        "breakpoint": 1600,
          "settings": {
            "slidesToShow": 3,
            "slidesToScroll": 3
          }
        }, {
        "breakpoint": 1200,
          "settings": {
            "slidesToShow": 3,
            "slidesToScroll": 3
          }
        }, {
        "breakpoint": 992,
          "settings": {
            "slidesToShow": 3,
            "slidesToScroll": 3
          }
        }, {
        "breakpoint": 768,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 2
        }
        }, {
        "breakpoint": 554,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1
        }
      }]
    });


    // initialization of fancybox
      $('.js-fancybox').each(function () {
        var fancybox = $.HSCore.components.HSFancyBox.init($(this));
      });

      // initialization of sticky block
      $('.js-sticky-block').each(function () {
        var stickyBlock = new HSStickyBlock($(this)).init();
      });

      // initialization of header
      var header = new HSHeader($('#header')).init();

      // initialization of mega menu
      var megaMenu = new HSMegaMenu($('.js-mega-menu')).init();

      $('.js-slick-carousel').each(function() {
      var slickCarousel = $.HSCore.components.HSSlickCarousel.init($(this));
    });


   });
  // JavaScript to be fired on all pages, after page specific JS is fired


})(jQuery); // Fully reference jQuery after this point.
